<template>
  <AuthPageLayout title="Войдите для начала работы">
    <AuthEmailPwd ref="form" :sign-in="onSubmit" />
  </AuthPageLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import AuthPageLayout from '@/components/layouts/AuthPageLayout';
import AuthEmailPwd from '@/components/auth/EmailPwd';
import { DISABLED_2FA } from '@/utils/constants';
import { WebStorage } from '@/plugins/webStorage';

export default {
  components: {
    AuthPageLayout,
    AuthEmailPwd,
  },

  mixins: [waitable],

  computed: {
    ...mapGetters('AUTH', ['isAuthorized', 'isMedic', 'isBasic']),
  },

  created() {
    if (window.opener) {
      window.opener.postMessage({ code: this.$route.query['code'] });
    }
  },

  watch: {
    isAuthorized(value) {
      // Выполняем действие, если компонент находится на экране (keep-alive)
      if (value && !this._inactive) {
        if (this.$route.query.redirect)
          this.$router
            .push(this.$route.query.redirect)
            .catch(err => console.error(err));
        else this.$router.push({ name: 'main' });
      }
    },
  },

  methods: {
    ...mapActions('AUTH', ['signIn', 'getDisabled2fa']),

    async onSubmit(credentials) {
      await this.getDisabled2fa();
      await this.signIn({
        credentials,
        type: 'email_pwd',
      });
      const disabled2fa = WebStorage.getItem(DISABLED_2FA) === 'true';
      // При авторизации пришел медик - отправляем проверять криптопро
      if (!this.isAuthorized && this.isMedic && !disabled2fa) {
        this.$router.push({
          name: 'login:token',
          query: { redirect: this.$route.query.redirect },
        });
        return;
      }

      this.$store.dispatch('initApplication');
    },
  },
};
</script>
